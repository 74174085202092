/*
*/

frappe.ui.form.AnimationController  = class AnimationController{
    constructor(args){
        $.extend(this, args)
        this.make();
    }

    make(){
        this.parse_assets();
        this.$main_wrapper = this.frm.$parent;
    }

    parse_assets(){
        this.form_assets = {};
        return new Promise((resolve, reject)=>{
            try{
                this.form_assets = JSON.parse(this.formmeta.form_assets);
            }catch(e){
                console.error(e);
            }
        });
    }

    set_form_start_background(){
        // moved this functionality to backend in order to improve load time.
        if(this.form_assets){
            let background = this.form_assets.desktop_start_background;
            if(bond_assets.device.is_mobile_device()){
                background = this.form_assets.desktop_start_background;
            }
            this.set_background_image(background);
        }
    }
    set_steps_background_image(){
        if(this.form_assets){
            if(bond_assets.device.is_mobile_device()){
                this.$main_wrapper
                    .css("background", "url('assets/bond_assets/images/backgrounds/texture-white-car-form-mob.svg')")
                    .css("background-size", "cover");
                
                this.$main_wrapper.find(".right-col-form").removeClass("form-padding");
            }
        }
    }

    play_form_start_animation(){
        let is_mobile_device = bond_assets.device.is_mobile_device();
        let animation_delay = 2800;
        if(is_mobile_device){
            animation_delay = 1;
        }
        return new Promise((resolve, reject)=>{
            this.$form_start_animation_wrapper = this.get_start_wrapper(this.form_assets);
            var me = this;
            if(is_mobile_device){
                /* disabling loading screen functionality for now.
                    me.$form_start_animation_wrapper.find(".container-form").removeClass("d-none");
                    me.frm.$steps_wrapper.addClass("d-none");
                */
                //timeout the video animation on mobile phone.
                setTimeout(()=>{
                    me.$form_start_animation_wrapper.addClass("d-none");
                    me.$form_start_animation_wrapper.find(".video-bg").addClass("d-none");
                    me.frm.$steps_wrapper.removeClass("d-none");
                    me.show_first_sub_step();
                    resolve();
                }, 1);
            }else{
                resolve();
            }
        });
    }

    show_first_sub_step(){
        let $step = this.frm.$parent.find(".step").first();
        $step.find(".substep-form").first().show();
        $step.show();
    }

    set_form_finish_backgroud(){
        let background = "/assets/bond_assets/images/backgrounds/quote-error-page-bg.jpg";
        if(this.form_assets){
            if(bond_assets.device.is_mobile_device()){
                background = this.form_assets.mobile_finish_background;
            }else if(this.form_assets.desktop_finish_background){
                background = this.form_assets.desktop_finish_background;
            }
            this.set_background_image(background);
        }
    }

    play_form_finish_animation(){
        //this.set_form_finish_backgroud();
        this.$form_finish_animation_wrapper = this.get_finish_wrapper(this.form_assets);
        if(bond_assets.device.is_mobile_device()){
            this.$form_finish_animation_wrapper.find(".mobile-finish-animation").removeClass("d-none");
        }else{
            this.$form_finish_animation_wrapper.find(".desktop-finish-animation").removeClass("d-none");
        }

        this.frm.$steps_wrapper.fadeOut(500);
        this.$main_wrapper.fadeTo(500, 0);
        this.$form_finish_animation_wrapper.find(".container-form").removeClass("d-none");
        this.$form_finish_animation_wrapper.slideDown(1300);
        this.$form_finish_animation_wrapper.find('.form-animation-finish-logo-wrapper').addClass('opacity-fade-in');
        this.$form_finish_animation_wrapper.find('.form-animation-finish-title-wrapper')
            .removeClass("d-none")
            .addClass('opacity-fade-in-text');

        $('html').toggleClass('overflow-hidden');
        this.$form_finish_animation_wrapper.css("display", "block");
    }

    display_form_finish_static_animation(){
        this.$form_finish_animation_wrapper = this.get_finish_wrapper(this.form_assets);
        if(bond_assets.device.is_mobile_device()){
            //this.$form_finish_animation_wrapper
            //  .find(".back-btn-wrapper")
            //  .addClass("mt-5");
        }
        /*this.$form_finish_animation_wrapper
            .addClass("h-100")
            .css("background", `url('assets/bond_assets/images/backgrounds/quote-error-page-bg.jpg')`)
            .css("background-size", "cover")
            .css("height", "1px");
        
        this.$form_finish_animation_wrapper
            .find(".form-animation-finish-title-wrapper")
            .removeClass("d-none")
            .css("display", "block");
        */
        this.frm.$steps_wrapper.fadeOut(500);
        this.$main_wrapper.fadeTo(500, 0);

        //this.$form_finish_animation_wrapper.find(".video-bg").addClass("d-none");
        //this.$form_finish_animation_wrapper.find(".container-form").removeClass("d-none");
        $('html').toggleClass('overflow-hidden');
        this.$form_finish_animation_wrapper.css("display", "block");

    }

    stop_form_start_animation(){

    }

    stop_form_finish_animation(){
        this.frm.$steps_wrapper.fadeIn(0);
        $(".main-form-landing").fadeTo(0, 1);
        this.$form_finish_animation_wrapper.slideUp(1300);
        this.$form_finish_animation_wrapper.find('.form-animation-finish-logo-wrapper').removeClass('opacity-fade-in');
        this.$form_finish_animation_wrapper.find('.form-animation-finish-title-wrapper').remmoveClass('opacity-fade-in-text');
        $('html').toggleClass('overflow-hidden');
    }

    set_background_image(background){
        this.$main_wrapper
            .css("background", `url('${background}')`)
            .css("background-size", "cover")
            .css("height", "1px");
    }
    
    get_finish_wrapper(form_assets){
        let message=`We’re thrilled to work with you and will keep you informed every step of the way. Our team is already hard at
                        work reviewing your application, and we’ll be in touch soon with updates. You can also schedule a time to speak with an agent at your convenience.`;
        let title="Thank you for submitting your information.";

        /*
        if(this.frm.form_controller && this.frm.form_controller.get_loading_screen_msg){
            message = this.frm.form_controller.get_loading_screen_msg();
        }
        if(this.frm.form_controller && this.frm.form_controller.get_loading_screen_title){
            title = this.frm.form_controller.get_loading_screen_title();
        }
        */
        // let animated_logo = this.get_success_image();
        let $wrapper = $(`<section id="js-form-finish" class="fixed-top form-results">
            <div class="container-form">
                <div class="d-flex pt-3 pt-sm-5">
                    <div
                        class="d-flex flex-column align-items-sm-center justify-content-sm-start w-100 min-vh-100 pt-2 pt-xxl-4">
                        <div class="row justify-content-center w-100 pt-2 pt-sm-3 ml-0 mr-0">
                            <div class="col-12 col-md-9">
                                <div class="py-sm-3 py-xxl-5 px-sm-3">
                                    <div id="js-content-box-opacity" class="px-0 px-lg-5 mx-0">
                                        <div class="col-12 text-center mb-4 py-0">
                                            <img src="/assets/bond_assets/images/thank-submitting-illustration.svg"
                                                alt="illistr-submitting" class="img-fluid">
                                        </div>
                                        <div class="col-12 col-md-10 col-lg-7 col-xl-6 mx-auto text-center mb-3 pb-1">
                                            <h3 class="helvetica-font submitting-text-title mb-3 mb-md-0 form-main-title">${title}</h3>
                                        </div>
                                        <div class="col-12 text-center mb-5 form-sub-title-wrapper">
                                            <p class="helvetica-font submitting-text-description mb-0 form-sub-title">${message}</p>
                                        </div>
                                        <div class="col-12 d-flex submitting-cta-wrap justify-content-center">
                                            <a href="#" class="submitting-back-to-btn animation-back-action-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.554 29.554">
                                                    <g id="icon" transform="translate(29.554 29.554) rotate(180)">
                                                    <path id="Контур_92309" data-name="Контур 92309" d="M14.777,0A14.777,14.777,0,1,1,0,14.777,14.777,14.777,0,0,1,14.777,0Z" fill="#161616"/>
                                                    <g id="Сгруппировать_35422" data-name="Сгруппировать 35422" transform="translate(12.212 19.96) rotate(-90)">
                                                        <g id="Сгруппировать_35411" data-name="Сгруппировать 35411" transform="translate(0)">
                                                        <path id="Контур_92308" data-name="Контур 92308" d="M0,0,5.184,6.139,10.367,0" fill="none" stroke="#fff" stroke-width="3"/>
                                                        </g>
                                                    </g>
                                                    </g>
                                                </svg>
                                                <span>BACK TO HOME</span>
                                            </a>
                                            <input type="button" name="next" class="next btn submit-call-btn btn-dark btn-xl mt-0 schedule-call-btn disabled" value="SCHEDULE CALL" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>`).insertBefore(this.$main_wrapper);

        this.$schedule_appointment_btn = $wrapper.find(".schedule-call-btn");
        if(!is_null(message)){
            $wrapper.find(".form-sub-title-wrapper").removeClass("d-none");
        }

        /*
        if(form_assets.form_finish_animation_desktop){
            $(`<video class="d-none d-lxg-block desktop-finish-animation" width="100%" height="100%" muted autoplay loop
                playsinline>
                <source src="${form_assets.form_finish_animation_desktop}" type="video/mp4">
            </video>`).appendTo($wrapper.find(".video-bg"));
        }
        if(form_assets.form_finish_animation_tablet){
            $(`<video class="d-none d-sm-block d-lxg-none tablet-finish-animation" width="100%" height="100%"
                muted autoplay loop
                playsinline>
                <source src="${form_assets.form_finish_animation_tablet}" type="video/mp4">
            </video>`).appendTo($wrapper.find(".video-bg"));
        }
        if(form_assets.form_finish_animation_mobile){
            $(`<video class="d-none d-sm-none mobile-finish-animation" width="100%" height="100%" muted autoplay loop
                playsinline>
                <source src="${form_assets.form_finish_animation_mobile}" type="video/mp4">
            </video>`).appendTo($wrapper.find(".video-bg"));
        }
        */
        this.handle_back_btn_event($wrapper);
        this.handle_schedule_a_call($wrapper);
        return $wrapper;
    }

    handle_back_btn_event($wrapper){
        
        $wrapper.find(".animation-back-action-btn").on("click", (e)=>{
            e.preventDefault();
            if(frappe.boot.landing_server){
                window.location.href = frappe.boot.landing_server;
            }else{
                window.location.href = "https://www.trybond.com";
            }
            return false;
        });
    }

    handle_schedule_a_call($wrapper){
        $wrapper.find(".schedule-call-btn").on("click", (e)=>{
            e.preventDefault();
            if($(e.currentTarget).hasClass("disabled")){
                return;
            }
            let params = this.frm.get_params();
            if(!is_null(params['ref-type']) && !is_null(params['ref-name'])){
                window.location.href = `/appt?ref-type=${params['ref-type']}&ref-name=${params['ref-name']}`;
            }else if(this.frm.reference_doc && this.frm.reference_doc.name){
                window.location.href = `/appt?ref-type=${this.frm.reference_doc.doctype}&ref-name=${this.frm.reference_doc.name}`;
            }else{
                let form_key = localStorage.getItem("form_key");
                window.location.href = `/appt?ref-type=${"Abandoned Form"}&ref-name=${form_key}`;

            }
            return false;
        });
    }

    get_start_wrapper(form_assets){

        /*
            Animation is only visible on Mobile devices.
        */
        let bond_arrived_logo = this.get_bond_arrived_logo();

        let $wrapper = $(`<section class="fixed-top form-results d-sm-none form-animation-start-wrapper" id="js-form-start">
                    <div class="video-bg"></div>
                    <div class="container-form d-none">
                        <div class="d-flex pt-3 pt-sm-5">
                            <div class="d-flex flex-column align-items-sm-center justify-content-sm-start w-100 min-vh-100 pt-2 pt-xxl-4">
                                <div class="row justify-content-center w-100 pt-2 pt-sm-3 ml-0 mr-0">
                                    <div class="col-12 col-md-9">
                                        <div class="py-sm-3 py-xxl-5 px-sm-3">
                                            <div class="text-center d-none d-sm-block form-animation-start-logo-wrapper">
                                                <img src="${bond_arrived_logo}" alt="" class="img-fluid" style="max-width: 240px;">
                                            </div>
                                            <div id=" class="px-0 px-sm-5 mx-lg-5 mx-xl-0 form-animation-start-title-wrapper">
                                                <h2 class="form-title text-center mt-4 mb-2 pt-3">
                                                    ${__("Let’s get started!")}
                                                </h2>
                                                <p class="font-form-description text-center px-4 px-sm-5 d-none"></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>`).insertBefore(this.$main_wrapper);
        
        if(form_assets.form_start_animation){
            $(`<video width="100%" height="100%" muted autoplay playsinline>
                <source src="${form_assets.form_start_animation}" type="video/mp4">
            </video>`).appendTo($wrapper.find(".video-bg"));
        }
        return $wrapper;
    }

    get_success_image(){
        let img =  "/assets/bond_assets/images/arrived.gif";
        if(this.frm.form_controller && this.frm.form_controller.get_success_image){
            img = this.frm.form_controller.get_success_image();
        }
        return img;
    }

    get_bond_arrived_logo(){
        return "/assets/bond_assets/images/arrived.svg";
    }

}