
import "bond_assets/public/js/upload.js";

frappe.ui.form.ControlAttach = class BondControlAttach extends frappe.ui.form.ControlData{
	make_input() {
		if(this.$input){
			return;
		}
		this.meta = {};
		this.files = [];
		this.files_dict = [];
		this.setup_wrapper_and_handler();
		this.init_events();
		this.$input = this.$wrapper.find("input");
		this.input = this.$input.get(0);
		this.set_input_attributes();
		this.has_input = true;
	}
	setup_wrapper_and_handler(){
		let required_files = [];
		if(this.custom_df && this.custom_df.required_files){
			required_files = this.custom_df.required_files;
		}
		required_files.forEach((fileinfo, idx)=>{
			this.meta[fileinfo.file_name] = fileinfo;
			$(`<div class="col-12 d-flex justify-content-center">
				<div class="input-wrapper input-upload-wrapper w-100">
					<div class="control-input-field input-group input-group-lg input-group-lg-control border border-dark control-input-field-wrapper justify-content-between mx-auto control-input-field-wrapper"
						data-fieldname='${this.df.fieldname}' filename='${fileinfo.file_name}'>
						<div class="control-input-value">
							<span class="helvetica-font">
								${fileinfo.file_name}
							</span>
							<span class="helvetica-font file-name text-muted ml-4"></span>
						</div>
						<div class="input-group-prepend">
							<span class="input-group-text">
								<span class="circle-icon">
									<svg id="icon_uploud" data-name="icon uploud" xmlns="http://www.w3.org/2000/svg" width="10.828" height="15.731" viewBox="0 0 10.828 15.731">
										<g id="Сгруппировать_35794" data-name="Сгруппировать 35794">
										<path id="Контур_92466" data-name="Контур 92466" d="M12.93,18.224H19V14.237h1.629a.751.751,0,0,0,.625-1.168L16.726,6.285a.917.917,0,0,0-1.527,0l-4.524,6.784a.751.751,0,0,0,.625,1.168H12.93Z" transform="translate(-10.548 -5.876)" fill="#fff"/>
										<rect id="Прямоугольник_5012" data-name="Прямоугольник 5012" width="6.08" height="1.57" transform="translate(2.388 14.161)" fill="#fff"/>
										</g>
									</svg>
									<svg xmlns="http://www.w3.org/2000/svg" width="15.897" height="13.689" viewBox="0 0 15.897 13.689">
										<g id="icon_done" data-name="icon done" transform="translate(1.062 1.087)">
										<path id="Контур_92528" data-name="Контур 92528" d="M11.961-.057,5.647,7.769,1.832,3.955,0,5.787l5.668,5.646L13.8,1.6Z" transform="translate(0 0.057)" fill="#feffff" stroke="#feffff" stroke-width="1.5"/>
										</g>
									</svg>                                                                    
								</span>
							</span>
						</div>
						<div class="w-100 invalid-tooltip small py-3 d-none" data-fieldname=''>
							<div class="d-flex align-items-center justify-content-center">
								<div class="mr-2 pb-1">
									<img src="/assets/bond_assets/images/icons/three-dots.svg" alt="" class="img-fluid">
								</div>
								<div class="text-left error-message"></div>
							</div>
						</div>
						<input class="inputfile d-none attachment" type="file" name="upload-pdf" accept="${this.get_file_extensions("PDF")}"
							data-fieldname='${this.df.fieldname}' data-fieldtype='${this.df.fieldtype}' file-type='${fileinfo.file_type}' 
							filename='${fileinfo.file_name}' />
					</div>
				</div>
			</div>`).appendTo(this.$wrapper.find(".input-main-wrapper"));
		});
	}

	init_events(){
		this.$wrapper.find(".control-input-field").on("click", (e)=>{
			if(e.target.nodeName == "INPUT"){
				return true;
			}
			e.preventDefault();
			let $cur_target = $(e.currentTarget); 
			$cur_target.find("input[type='file']").click();
			return false;
		});

		// when user select a file to upload/pdfs'
		this.$wrapper.find("input").on("change", (e)=>{
			let file_info = this.meta[$(e.currentTarget).attr("filename")];
			let $wrapper = this.$wrapper.find(`.control-input-field-wrapper[filename='${file_info.file_name}']`);
			this.set_files("PDF", e.target.files, file_info).then(()=>{
				let _file = this.files_dict[file_info.file_name];
				this.on_upload_complete();
				$wrapper.addClass("active");
				$wrapper.find(".file-name").text(`(${_file.filename})`);
			});
			return true;
		});
	}

	async set_files(options, files, file_info){
		for(const file of files){
			var fileinfo = await this.parse_file(file, file_info);
			fileinfo.data = fileinfo.content;
			this.files_dict[file_info.file_name] = fileinfo;
		}
		return await this.update_files();
	}

	update_files(){
		return new Promise((resolve, reject)=>{
			this.files = [];
			for(var file_name in this.files_dict){
				this.files.push(this.files_dict[file_name]);
			}
			resolve();
		});
	}

	parse_file(file, file_info){
		let _fileinfo = {
				file: file,
				readable_size: this.get_formatted_file_size(file.size),
				type: file.type,
				file_type: file_info.file_type,
				file_ext: (/[.]/.exec(file.name)) ? /[^.]+$/.exec(file.name) : undefined,
		};
		_fileinfo.filename = __("{0}.{1}", [file_info.file_name.replace(/[^A-Z0-9]/ig, "_"), _fileinfo.file_ext]);
		return new Promise((resolve, reject)=>{
			var reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = function () {
				_fileinfo.content = reader.result;
				resolve(_fileinfo);
			};
			reader.onerror = function (error) {
				reject(error);
			};
		});
	}

	clear_attachment() {
		var me = this;
		let promise;
		if(this.frm) {
			promise = me.parse_validate_and_set_in_model([]);
		} else {
			this.dataurl = null;
			this.fileobj = null;
			this.set_input([]);
			promise = this.parse_validate_and_set_in_model([]);
		}

		if(promise && promise.then){
			promise.then((value)=>{
				this.refresh();
				this.frm.files[this.df.fieldname] = [];
				this.handle_form_events();
			});
		}
	}

	set_input(value) {
		this.files = is_null(value)?[]: value;
	}

	get_value() {
		return this.get_input_value();
	}

	get_input_value(){
		let value = [];
		if(this.df.reqd && this.custom_df && this.custom_df.required_files &&
			this.custom_df.required_files.length == this.files.length){
			value = this.files;
		}else if(!this.df.reqd){
			value = this.files;
		}
		return value;
	}

	async on_upload_complete() {
		if(this.frm) {
			this.parse_validate_and_set_in_model(this.files).then((value)=>{
				this.frm.files[this.df.fieldname] = this.files;
				if(this.layout && this.layout.call_events){
					this.layout.call_events(this.$input, this);
				}
			});
		}else{
			this.set_value(this.files);
		}
	}

	bind_change_event() {
	}

	get_fullfill_status(){
		let required_files = this.custom_df && this.custom_df.required_files?this.custom_df.required_files.length:1;
		return this.df.reqd?this.files.length == required_files: true;
	}
		/**
	 * Format bytes as human-readable text.
	 * 
	 * @param bytes Number of bytes.
	 * @param si True to use metric (SI) units, aka powers of 1000. False to use 
	 *           binary (IEC), aka powers of 1024.
	 * @param dp Number of decimal places to display.
	 * 
	 * @return Formatted string.
	 */
	get_formatted_file_size(bytes, si=false, dp=1) {
		const thresh = si ? 1000 : 1024;
	
		if (Math.abs(bytes) < thresh) {
			return bytes + ' B';
		}
	
		const units = si 
					? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
					: ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
		let u = -1;
		const r = 10**dp;
	
		do {
			bytes /= thresh;
			++u;
		} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
	
	
		return bytes.toFixed(dp) + ' ' + units[u];
	}

	allow_multiple_files(){
		let allow=false;
		if(this.fieldobj && this.fieldobj.custom_df && this.fieldobj.custom_df.allow_multiple_files){
			allow=true;
		}
		return allow;
	}

	get_file_extensions(options){
		let content_type = "";
		if(options == "Image"){
			content_type = "image/*";
		}else if(options == "PDF"){
			content_type = "application/pdf, image/*, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .csv";
		}
		return content_type;
	}

	has_all_the_attachments(){
		let required_files = this.custom_df && this.custom_df.required_files?this.custom_df.required_files.length:1;
		return this.files.length == required_files?true:false;
	}
};